import STATUS from '../constants/apiStatus';
import { useState, useCallback, useEffect } from 'react';
// import { notify } from '@/components/Notification';


const useAsync = ({ asyncFunction, immediate = false, paramsToPassImmediate }) => {
  const [state, setState] = useState({
    status: STATUS.idle, value: null, error: null
  })
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback((params) => {
    setState({
      status: STATUS.pending, value: null, error: null
    })
    return asyncFunction(params)
      .then((response) => {
        return new Promise((resolve, reject)=> {
          setTimeout(() => {
            setState({
              status: STATUS.success, value: response, error: null
            })
            resolve(response)
          }, 0);
        })
      })
      .catch((error) => {
        setState((_state) => ({
          ..._state, status: STATUS.error, error
        }))
        // notify.error({
        //   message: 'Error',
        //   description: error?.response?.data?.errors?.[0] || error?.response?.data?.message || 'Something went wrong.',
        // });
        });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncFunction]);
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute(paramsToPassImmediate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, immediate]);

  return { execute, status: state?.status, value: state?.value, error: state?.error };
};

export default useAsync;