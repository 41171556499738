import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import { BACKGROUND_LAYOUT_GRAY } from "../../constants/colors";

const Layout = ({ children }) => {
  return (
    <>
      <AppHeader />
      <Box
        sx={{
          height: `100%`,
          marginTop: '77px',
          padding: { lg: "30px 162px", md: "30px 12px", xs: "30px 12px" },
          backgroundColor: BACKGROUND_LAYOUT_GRAY,
        }}
      >
        {children}
      </Box>
      <AppFooter />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default React.memo(Layout);
