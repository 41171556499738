import { ADD_NOTIFICATION, CLEAR_NOTIFICATION } from '../notification/types';

export const addNotification = (payload) => ({
  type: ADD_NOTIFICATION,
  payload,
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});
