import { lazy } from 'react';
import endpoints from './endpoints';

export const routes = [
  {
    path: endpoints.userManagement,
    component: lazy(() => import('../views/UserManagement')),
    exact: true,
  },
  {
    path: endpoints.userDetails,
    component: lazy(() => import('../views/UserManagement/UserDetails')),
    exact: true,
  },
  {
    path: endpoints.adManagement,
    component: lazy(() => import('../views/AdManagement')),
    exact: true,
  },
];

export const adminRoutes = [
  {
    path: endpoints.adminManagement,
    component: lazy(() => import('../views/AdminManagement')),
    exact: true,
  },
  {
    path: endpoints.userManagement,
    component: lazy(() => import('../views/UserManagement')),
    exact: true,
  },
  {
    path: endpoints.adManagement,
    component: lazy(() => import('../views/AdManagement')),
    exact: true,
  },
  {
    path: endpoints.userDetails,
    component: lazy(() => import('../views/UserManagement/UserDetails')),
    exact: true,
  },
  {
    path: endpoints.settings,
    component: lazy(() => import('../views/Settings')),
    exact: true,
  },
];
