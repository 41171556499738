import React, { useRef, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  FormControl,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

const InputPasswordField = ({
  id,
  placeholder,
  fullWidth,
  label,
  sx,
  containerSx,
  labelSx,
  error,
  onBlur,
  handleChange,
  name,
  value,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const ref = useRef(null);

  const handleRefChange = (event) => {
    ref.current.focus();
    handleChange(event);
  };

  return (
    <FormControl fullWidth  sx={containerSx}>
      <TextField
        id={id}
        label={label}
        variant="filled"
        placeholder={placeholder}
        inputRef={ref}
        value={value}
        error={error}
        type={showPassword ? "text" : "password"}
        name={name}
        onBlur={onBlur}
        onChange={handleRefChange}
        inputProps={{
          style: { backgroundColor: "white" },
        }}
        sx={{
          marginTop: "10px",
          position: "relative",
          ".MuiFilledInput-root:before": {
            border: "1px solid rgba(0, 0, 0, 0.42)",
            height: "100%",
            borderRadius: "5px",
          },
          ".MuiFilledInput-root:after": {
            border: "2px solid #1976d2",
            height: "97%",
            transition: "transform",
            borderRadius: "5px",
          },
          ".MuiFilledInput-root.Mui-error:after": {
            borderColor: "#d32f2f",
            borderRadius: "5px",
          },
          ".MuiFilledInput-root.Mui-error:before":
            {
              borderColor: "#d32f2f",
              borderRadius: "5px",
            },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prev) => !prev)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error && (
        <Box>
          <Typography
            sx={{
              color: "red",
              fontSize: "14px",
            }}
          >
            {error.id}
          </Typography>
        </Box>
      )}
    </FormControl>
  );
};

InputPasswordField.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  sx: PropTypes.object,
  containerSx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  labelSx: PropTypes.object,
  error: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

InputPasswordField.defaultProps = {
  id: "",
  label: "",
  fullWidth: false,
  sx: {},
  containerSx: {},
  labelSx: {},
  error: false,
};

export default InputPasswordField;
