import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tabs,
  MenuItem,
  Tab,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as Logo } from "../../../../assets/images/Logo.svg";
import {
  AVATAR_BACKGROUND,
  AVATAR_TEXT,
  BLACK,
  DROPDOWN_HOVER,
  PRIMARY_2,
  WHITE,
} from "../../../constants/colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import endpoints from "../../../../routes/endpoints";
import { UserContext } from "../../../../globalContext/user/userProvider";
import { setSelectedTab } from "../../../../globalContext/user/userActions";
import { addNotification } from "../../../../globalContext/notification/notificationActions";
import { NotificationContext } from "../../../../globalContext/notification/notificationProvider";
import useAsync from "../../../../hooks/useAsync";
import { logoutApi } from "../../../../services/authService";
import STATUS from "../../../../constants/apiStatus";
import ChangePasswordModal from "./ChangePasswordModal";

const settings = ["Change Password", "Log Out"];
const adminTabs = [
  {
    value: 1,
    label: "Admin Management",
    redirectURL: endpoints?.adminManagement,
  },
  {
    value: 2,
    label: "User Management",
    redirectURL: endpoints?.userManagement,
  },
  {
    value: 3,
    label: "Ad Management",
    redirectURL: endpoints?.adManagement,
  },
  {
    value: 4,
    label: "Settings",
    redirectURL: endpoints?.settings,
  },
];

const tabs = [
  {
    value: 1,
    label: "User Management",
    redirectURL: endpoints?.userManagement,
  },
  {
    value: 2,
    label: "Ad Management",
    redirectURL: endpoints?.adManagement,
  },
];

const AppHeader = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isNewModalOpen, setNewPassModalOpen] = React.useState({
    isOpen: false,
    isNeedPasswordReset: true,
  });
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const userData = JSON.parse(localStorage.getItem("USER_DATA"));
  const isSuperAdmin = userData?.userType === 0;
  const isNeedPasswordReset = userData?.registerType === "5";
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [tabValue, setTabValue] = React.useState(1);
  const {
    execute: executeLogOut,
    status: logOutStatus,
    value: logOutValues,
    error: logOutError,
  } = useAsync({ asyncFunction: logoutApi });
  const [store, dispatch] = React.useContext(UserContext);
  const [notificationStore, notificationDispatch] =
    React.useContext(NotificationContext);

  React.useEffect(() => {
    const currentUrlArr = currentUrl.split("/");
    const selectedTabVal = (isSuperAdmin ? adminTabs : tabs)?.filter((_tab) =>
      currentUrlArr.includes(_tab?.redirectURL.split("/")[1])
    );
    setTabValue(selectedTabVal[selectedTabVal?.length - 1]?.value);
  }, [currentUrl]);

  React.useEffect(() => {
    if (isNeedPasswordReset) {
      setNewPassModalOpen({
        isOpen: true,
        isNeedPasswordReset: true,
      });
    }
  }, [isNeedPasswordReset]);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  React.useEffect(() => {
    if (logOutStatus === STATUS?.error) {
      dispatch(
        addNotification({
          isError: true,
          message: logOutError?.response?.data?.errors?.[0],
        })
      );
    }
    if (logOutStatus === STATUS?.success) {
      localStorage?.clear();
      sessionStorage?.clear();
      notificationDispatch(
        addNotification({ isError: false, message: "Logout Successful" })
      );
      navigate(endpoints?.login);
    }
  }, [logOutValues, logOutStatus]);

  const handleCloseUserMenu = (data) => {
    setAnchorElUser(null);
    if (data === "Log Out") {
      executeLogOut({ userId: userData?.userId });
    } else if (data === "Change Password") {
      setNewPassModalOpen({
        isOpen: true,
        isNeedPasswordReset: false,
      });
    }
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: AVATAR_BACKGROUND,
        color: AVATAR_TEXT,
        width: 32,
        height: 32,
        fontSize: "17px",
        fontWeight: 600,
        marginRight: "10px",
      },
      children: `${name?.split(" ")[0][0] || ""}`,
    };
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ padding: "14px 0px", backgroundColor: WHITE }}
    >
      <Container
        maxWidth="xl"
        sx={{ maxHeight: "49px" }}
        style={{ padding: "0px 12px" }}
      >
        <Toolbar
          disableGutters
          sx={{ margin: { lg: "0px 150px", md: "0px" }, height: "49px" }}
          style={{ minHeight: "49px" }}
        >
          <Logo
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            style={{ width: "230px", height: "40px" }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "end",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
              variant="outlined"
            >
              <MenuIcon sx={{ color: BLACK }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              sx={{
                ".MuiMenu-paper": {
                  width: "200px",
                  maxWidth: "200px",
                },
                marginTop: "4px",
              }}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Box>
                <Box
                  sx={{
                    flexGrow: 0,
                    padding: "7px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar {...stringAvatar(userData?.userName)} />
                  <Tooltip title={userData?.userName}>
                    <Typography
                      fontSize="14px"
                      fontWeight={600}
                      sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", }}
                    >
                      {userData?.userName}
                    </Typography>
                  </Tooltip>
                </Box>
                <Divider />
                <Box sx={{ padding: "2px 0px" }}>
                  {(isSuperAdmin ? adminTabs : tabs)?.map((_tab) => {
                    return (
                      <Box
                        sx={{
                          padding: "7px 16px",
                          backgroundColor:
                            tabValue === _tab?.value && PRIMARY_2,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          "&:hover": {
                            backgroundColor: DROPDOWN_HOVER,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => {
                          setTabValue(_tab?.value);
                          dispatch(setSelectedTab(_tab?.value));
                          navigate(
                            (isSuperAdmin ? adminTabs : tabs)?.filter(
                              (_d) => _d?.value === _tab?.value
                            )[0]?.redirectURL
                          );
                          setAnchorEl(null);
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {_tab?.label}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                <Divider />
                <Box sx={{ padding: "2px 0px" }}>
                  {settings?.map((_d) => {
                    return (
                      <Box
                        sx={{
                          padding: "7px 16px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          "&:hover": {
                            backgroundColor: DROPDOWN_HOVER,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => handleCloseUserMenu(_d)}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {_d}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
            }}
          >
            <Tabs
              value={tabValue}
              sx={{ minHeight: "0px" }}
              aria-label="wrapped label tabs example"
            >
              {(isSuperAdmin ? adminTabs : tabs)?.map((_tab) => {
                return (
                  <Tab
                    sx={{
                      textTransform: "none",
                      marginLeft: _tab?.label !== "Admin Management" && "22px",
                      minHeight: "0px",
                      fontSize: "14px",
                      fontWeight: 600,
                      padding: "8px 4px",
                    }}
                    onClick={() => {
                      setTabValue(_tab?.value);
                      dispatch(setSelectedTab(_tab?.value));
                      navigate(
                        (isSuperAdmin ? adminTabs : tabs)?.filter(
                          (_d) => _d?.value === _tab?.value
                        )[0]?.redirectURL
                      );
                    }}
                    value={_tab?.value}
                    label={_tab?.label}
                    wrapped
                  />
                );
              })}
            </Tabs>
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
              marginLeft: "30px",
            }}
          >
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{
                p: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Avatar {...stringAvatar(userData?.userName)} />
              <Typography fontSize="14px" fontWeight={600}>
                {userData?.userName}
              </Typography>
              <ArrowDropDownIcon />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <ChangePasswordModal
        executeLogOut={executeLogOut}
        email={userData?.email}
        userId={userData?.userId}
        isNewModalOpen={isNewModalOpen}
        setNewPassModalOpen={setNewPassModalOpen}
      />
    </AppBar>
  );
};
export default AppHeader;
