import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  Box,
  Typography,
  Card,
  Divider,
  IconButton,
} from "@mui/material";
import Form from "./Form";
import useAsync from "../../../../../hooks/useAsync";
import { resetPasswordApi } from "../../../../../services/authService";
import STATUS from "../../../../../constants/apiStatus";
import { NotificationContext } from "../../../../../globalContext/notification/notificationProvider";
import { addNotification } from "../../../../../globalContext/notification/notificationActions";
const ChangePasswordModal = ({
  executeLogOut,
  email,
  userId,
  isNewModalOpen,
  setNewPassModalOpen,
}) => {
  const {
    execute: executeChangePassword,
    status: changePasswordStatus,
    value: changePasswordValues,
    error: changePasswordError,
  } = useAsync({ asyncFunction: resetPasswordApi });

  const [store, dispatch] = useContext(NotificationContext);

  const handleSubmit = (values) => {
    const { currentPassword, newPassword, confirmPassword } = values;
    const payload = {
      userId,
      email,
      newPassword,
      confirmPassword,
      currentPassword,
    };
    executeChangePassword(payload);
  };

  useEffect(() => {
    if (changePasswordStatus === STATUS?.error) {
      dispatch(
        addNotification({
          isError: true,
          message: changePasswordError?.response?.data?.errors?.[0]?.message,
        })
      );
    }
    if (changePasswordStatus === STATUS?.success) {
      const userData = JSON.parse(localStorage.getItem("USER_DATA"));
      if (userData?.registerType === "5") {
        const updatedUserData = { ...userData, registerType: "1" };
        localStorage.setItem("USER_DATA", JSON.stringify(updatedUserData));
      }
      dispatch(
        addNotification({
          isError: false,
          message: changePasswordValues?.message,
        })
      );
      setNewPassModalOpen({
        isOpen: false,
        isNeedPasswordReset: false,
      });
    }
  }, [changePasswordStatus]);

  return (
    <Modal
      open={isNewModalOpen?.isOpen}
      onClose={() => {
        if (!isNewModalOpen?.isNeedPasswordReset) {
          setNewPassModalOpen({
            isOpen: false,
            isNeedPasswordReset: false,
          });
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          width: { xs: 356, sm: 400, md: 498 },
          position: "absolute",
          top: "6%",
          left: "50%",
          transform: "translateX(-50%)",
          boxShadow:
            "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "16px",
            boxSizing: "border-box",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            Change Password
          </Typography>
          {!isNewModalOpen?.isNeedPasswordReset && (
            <IconButton
              onClick={() => {
                setNewPassModalOpen({
                  isOpen: false,
                  isNeedPasswordReset: false,
                });
              }}
              sx={{ padding: "0px" }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
        <Divider />
        <Box sx={{ padding: "16px 16px 0px 16px" }}>
          <Typography
            sx={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Is it time for a change? Choose a strong new password and finish by
            clicking Save.
          </Typography>
        </Box>
        <Form
          onSubmit={handleSubmit}
          setIsOpen={setNewPassModalOpen}
          isNewModalOpen={isNewModalOpen}
        />
      </Card>
    </Modal>
  );
};

ChangePasswordModal.defaultProps = {
  isNewModalOpen: false,
  setNewPassModalOpen: () => {},
};

ChangePasswordModal.propTypes = {
  isNewModalOpen: PropTypes.object.isRequired,
  setNewPassModalOpen: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
