import axios from "axios";
import { getToken } from "./libs/auth";
import { createBrowserHistory } from "history";
import endpoints from "./routes/endpoints";

const history = createBrowserHistory();

// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    config.headers = {
      'X-device-type':'web-app',
    };
    const token = getToken();
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'X-device-type':'web-app',
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error?.response?.status === 401 &&
      !error?.response?.request?.responseURL.includes("login")
    ) {
      localStorage.clear();
      sessionStorage.clear();
      history.replace(endpoints?.login);
      window.location.reload();
      // signOut({ redirect: false });
    }
    if (error.response?.status === 403) {
      // history.replace(endpoints?.userManagement);
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);
