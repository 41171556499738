import React from 'react';
import PropTypes from 'prop-types';
import { isTokenAvailable } from '../../../libs/auth';
import { Navigate } from 'react-router-dom';
import endpoints from '../../../routes/endpoints';

function LogoutRequired(props) {
  const { children } = props;
  return isTokenAvailable() ? <Navigate to={endpoints.adminManagement} /> : children;
}

LogoutRequired.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LogoutRequired;