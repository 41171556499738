import "./App.css";
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RouteLoader from "./common/components/RouteLoader/RouteLoader";
import LogoutRequired from "./common/components/RouteWrappers/LogoutRequired";
import ProtectedRoutes from "./common/components/RouteWrappers/ProtectedRoutes";

function App() {
  const LoginPage = lazy(() => import("./views/Login"));
  return (
    <BrowserRouter>
      <Suspense fallback={<RouteLoader />}>
        <Routes>
          <Route
            path="/login"
            element={
              <LogoutRequired>
                <LoginPage />
              </LogoutRequired>
            }
          />
          <Route path="/*" element={<ProtectedRoutes />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
