import React, { useContext, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Error } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { NotificationContext } from "../../../globalContext/notification/notificationProvider";
import { clearNotification } from "../../../globalContext/notification/notificationActions";

const AlertView = React.forwardRef((props, ref) => (
  <MuiAlert
    elevation={6}
    ref={ref}
    variant="filled"
    {...props}
    iconMapping={{
      success: <CheckCircleOutlineIcon />,
      error: <Error />,
    }}
  />
));

const Alert = () => {
  const [store, dispatch] = useContext(NotificationContext);
  const { isError, isOpen, message } = store;
  const [open, setOpen] = React.useState(isOpen);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(clearNotification());
  };

  useEffect(() => {
    if (!isOpen) {
      dispatch(clearNotification());
    }
    setOpen(isOpen);
  }, [open, isOpen]);

  return (
    <>
      {message !== "" && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <AlertView
            onClose={handleClose}
            severity={isError ? "error" : "success"}
            sx={{
              width: "100%",
              alignItems: "center",
            }}
          >
            {message}
          </AlertView>
        </Snackbar>
      )}
    </>
  );
};

Alert.defaultProps = {};

Alert.propTypes = {};

export default Alert;
