import * as React from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";
import { WHITE, BLACK } from "../../../constants/colors";

export default function AppFooter() {
  const currentDate = new Date();
const currentYear = currentDate.getFullYear();
  return (
    <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0, height: '42px', backgroundColor: WHITE }}>
      <Toolbar sx={{ height: "42px", display: 'flex', justifyContent: 'center' }}
          style={{ minHeight: "42px" }}>
        <Typography color={BLACK} fontSize={'14px'}>© {currentYear}, MyAssetTracker</Typography>
      </Toolbar>
    </AppBar>
  );
}
